import { showError } from '@/helpers/globalHelpers';
import moment from 'moment';
import { mapActions } from 'vuex';
export default {
	name: 'ModificationsTab',
	data() {
		return {
			headersAssetModificationHistory: [
				{
					text: 'Propiedad',
					align: 'start',
					sortable: false,
					value: 'field_changed',
				},
				{
					text: 'Valor anterior',
					align: 'start',
					sortable: false,
					value: 'old_value',
				},
				{
					text: 'Nuevo valor',
					sortable: false,
					value: 'new_value',
				},
				{
					text: 'Responsable',
					align: 'center',
					sortable: false,
					value: 'responsible.fullName',
				},
				{
					text: 'Fecha de modificación',
					align: 'start',
					sortable: false,
					value: 'created_at',
				},
			],
			headersAssetStatusHistory: [
				{
					text: 'Estado',
					sortable: false,
					value: 'status',
				},
				{
					text: 'Responsable',
					align: 'start',
					sortable: false,
					value: 'responsible.fullName',
				},
				{
					text: 'Fecha de modificación',
					align: 'start',
					sortable: false,
					value: 'created_at',
				},
			],
		};
	},

	props: {
		value: Boolean,
		item: Object,
	},

	computed: {},

	created() { },

	methods: {
		...mapActions('assetUnsubscribe', ['getAssetReturUnsubscribeCertificate']),
		// getStatusColor(status) {
		// 	switch (status) {
		// 		case 'MOVILIZANDO':
		// 			return 'blue accent-4';
		// 		case 'CANCELADO':
		// 			return 'red';
		// 		case 'RECEPCIONADO':
		// 		case 'ASIGNADO':
		// 			return { background: 'blue lighten-5', color: 'blue' };
		// 		case 'RECHAZADO':
		// 			return 'blue-grey';
		// 		default:
		// 			return ''; // color por defecto o podrías retornar algún color en particular
		// 	}
		// },

		getStatusColor(status) {
			switch (status) {
				case 'DISPONIBLE':
					return {
						background: 'green lighten-5',
						color: 'green',
						name: status
					}
				case 'PREASIGNADO':
					return {
						background: 'light-blue lighten-5',
						color: 'light-blue',
						name: status,
					}
				case 'ASIGNADO':
					return {
						background: 'blue lighten-5',
						color: 'blue',
						name: status
					}
				case 'REVISION':
					return {
						background: '#FFF3E0',
						color: 'orange',
						name: status
					}
				case 'MANTENIMIENTO':
					return {
						background: 'purple lighten-5',
						color: 'purple',
						name: status
					}
				case 'BAJA':
					return {
						background: 'grey lighten-3',
						color: 'grey',
						name: status
					}
			}
		},

		changeNameProperty(name) {
			switch (name) {
				case 'name':
					return 'NOMBRE';
				case 'purchase_value':
					return 'VALOR ADQUISICIÓN'
				case 'purchase_date':
					return 'FECHA DE COMPRA'
				case 'serial_number':
					return 'NUMERO DE SERIE';
				case 'num_asset':
					return 'NÚMERO DE ACTIVO'
				case 'tag':
					return 'ETIQUETA'
				case 'observations':
					return 'OBSERVACIONES'
				case 'maintenance_cost':
					return 'COSTO MANTENIMIENTO'
				case 'other_cost':
					return 'OTROS COSTOS'
				case 'equipment_model':
					return 'MODELO DE EQUIPO'
				case 'oc':
					return 'OC'
				case 'equipment_type':
					return 'TIPO DE EQUIPO'
				case 'monitor_model':
					return 'MODELO DE MONITOR'
				case 'monitor_brand':
					return 'MARCA DE MONITOR'
				case 'useful_life':
					return 'VIDA ÚTIL'

			}
		},

		formatDate(dateString) {
			if (!dateString) {
				return '-';
			}
			return moment(dateString).format('DD/MM/YYYY HH:mm');
		},

		async downloadCertificate() {
			try {
				//const { assetAssignId } = this.$route.params;
				const { ok, response } = await this.getAssetReturUnsubscribeCertificate(this.item.unsubscribe.id);
				if (ok) {
					const url = window.URL.createObjectURL(new Blob([response]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'acta_baja_activo.pdf');
					document.body.appendChild(link);
					link.click();
				}
			} catch (error) {
				showError(error);
			}
		}
	},

	components: {},
};
