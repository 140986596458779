var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"px-0 pt-2 pb-0",attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])})],1),_c('v-container',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Detalle de Mantenimiento")]),(_vm.assetMaintenance)?_c('v-container',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('table',[_c('tbody',[_c('tr',[_c('td',{staticClass:"primary--text pa-1 pr-4"},[_vm._v("Tipo")]),_c('td',{staticClass:"pa-1"},[_vm._v(_vm._s(_vm.assetMaintenance?.type))])]),_c('tr',[_c('td',{staticClass:"primary--text pa-1 pr-4"},[_vm._v(" Fecha de inicio: ")]),_c('td',{staticClass:"pa-1"},[_vm._v(" "+_vm._s(_vm.formatDate( _vm.assetMaintenance?.maintenance_start_date ))+" ")])]),_c('tr',[_c('td',{staticClass:"primary--text pa-1 pr-4"},[_vm._v(" Registrado por: ")]),_c('td',{staticClass:"pa-1"},[_vm._v(" "+_vm._s(_vm.assetMaintenance?.responsible?.fullName)+" ")])]),_c('tr',[_c('td',{staticClass:"primary--text pa-1 pr-4"},[_vm._v("Estado:")]),_c('td',{staticClass:"pa-1"},[(
												_vm.getStateProperties(
													_vm.assetMaintenance.status
												)
											)?_c('v-chip',{attrs:{"small":"","color":_vm.getStateProperties(
													_vm.assetMaintenance.status
												)?.background,"text-color":_vm.getStateProperties(
													_vm.assetMaintenance.status
												)?.color}},[_vm._v(" "+_vm._s(_vm.getStateProperties( _vm.assetMaintenance.status ).text)+" ")]):_vm._e()],1)]),_c('tr',[_c('td',{staticClass:"primary--text pa-1 pr-4"},[_vm._v(" Comentario: ")]),_c('td',{staticClass:"pa-1"},[_vm._v(" "+_vm._s(_vm.assetMaintenance?.comment)+" ")])])])])]),_c('v-col',{staticClass:"d-flex flex-column justify-end align-end",attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"text-center mb-2",attrs:{"disabled":_vm.assetMaintenance?.status === 'COMPLETADO'
									? true
									: false,"color":"primary","width":"200"},on:{"click":function($event){return _vm.registerMaintenance()}}},[_vm._v("Dar de alta")])],1)],1)],1):_vm._e()],1),_c('register-asset-maintenance',{attrs:{"item":_vm.asset,"releaseAsset":true},on:{"getAssetMaintenanceId":_vm.loadMaintenanceById},model:{value:(_vm.registerMaintenanceDialog),callback:function ($$v) {_vm.registerMaintenanceDialog=$$v},expression:"registerMaintenanceDialog"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }