import { mapState, mapActions } from 'vuex';
import RegisterAssetMaintenance from '@/components/inventory/elements/RegisterAssetMaintenance/index.vue';
import moment from 'moment';

export default {
    name: 'MaintenanceTable',
    data() {
        return {
            registerMaintenanceDialog: false,
            loading: false,
            headers: [
                {
                    text: 'Fecha de Inicio',
                    align: 'start',
                    sortable: false,
                    value: 'maintenance_start_date',
                },
                {
                    text: 'Fecha de culminación',
                    align: 'start',
                    sortable: false,
                    value: 'maintenance_end_date',
                },
                {
                    text: 'Costo de mantenimiento',
                    align: 'start',
                    sortable: false,
                    value: 'maintenance_cost',
                },
                {
                    text: 'Tipo',
                    sortable: false,
                    value: 'type',
                },
                {
                    text: 'Registrado por',
                    align: 'start',
                    sortable: false,
                    value: 'responsible.fullName',
                },
                {
                    text: 'Estado',
                    align: 'start',
                    sortable: false,
                    value: 'status',
                },
                {
                    text: 'Acciones',
                    sortable: false,
                    value: 'actions',
                },
            ],
        };
    },


    props: {
        value: Boolean,
        item: Object,
    },


    computed: {
        ...mapState('asset', ['asset', 'sparesByAsset']),
    },

    created() { },

    methods: {
        ...mapActions('asset', ['getAssetById', 'getSparesByAsset']),
        async getAssetId() {
            try {
                this.isLoading = true;
                const id = this.$route.params.assetId;
                await this.getAssetById(id);

            } catch (error) {
                this.listError = Array.isArray(error?.data?.message)
                    ? error?.data?.message
                    : [error?.data?.message] || [];
            }
        },
        getStatusColor(status) {
            switch (status) {
                case 'INICIADO':
                    return { background: 'blue lighten-4', color: 'blue' };
                case 'COMPLETADO':
                    return { background: 'green lighten-5', color: 'green' };
                default:
                    return '';
            }
        },

        registerMaintenance() {
            this.registerMaintenanceDialog = true;
        },

        testEvent() {
            this.$emit('getAssetId');
        },
        formatDate(dateString) {
            if (!dateString) {
                return '-';
            }
            return moment(dateString).format('DD/MM/YYYY HH:mm');
        },
    },

    components: {
        RegisterAssetMaintenance
    },
};
