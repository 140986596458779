var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"px-0 py-5",attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])}),(_vm.assetAssignment)?_c('v-card',{staticClass:"pa-4 mb-2",attrs:{"rounded":"lg","flat":""}},[_c('div',{staticClass:"text-h6 mb-4"},[_vm._v(" Detalle de asignación #"+_vm._s(_vm.assetAssignment?.id)+" ")]),_c('div',{staticClass:"d-flex flex-wrap",style:({ width: '100%' })},[_c('v-btn',{staticClass:"ma-1",staticStyle:{"width":"200px"},attrs:{"color":"primary","outlined":"","disabled":_vm.isPreAssigned},on:{"click":function($event){return _vm.addUserSigner()}}},[_vm._v("Agregar firmante "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-account-plus")])],1),_c('v-btn',{staticClass:"ma-1",staticStyle:{"width":"250px"},attrs:{"color":"primary","outlined":"","disabled":_vm.isPreAssigned},on:{"click":function($event){return _vm.manualNotification()}}},[_vm._v("Notificar manualmente "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-bell-plus")])],1),_c('v-btn',{staticClass:"ma-1",staticStyle:{"width":"200px"},attrs:{"disabled":_vm.isPreAssigned,"color":"primary","outlined":"","loading":_vm.isLoading},on:{"click":function($event){return _vm.declineAssignmentAsset()}}},[_vm._v("Anular")]),_c('v-btn',{staticClass:"ma-1",staticStyle:{"width":"200px"},attrs:{"disabled":_vm.isAssigned,"color":"primary","outlined":"","to":{
						name: 'ReturnMultipleAssignments',
						params: { assetAssignmentId: _vm.assetAssignment?.id },
					}}},[_vm._v("Devolver "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-restore")])],1)],1)]):_vm._e(),_c('v-card',{attrs:{"rounded":"lg","loading":_vm.loading,"flat":""}},[_c('v-tabs',{attrs:{"background-color":"transparent","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Detalles")]),_c('v-tab',[_vm._v("Asignaciones")]),_c('v-tab',[_vm._v("Devoluciones")])],1),(_vm.assetAssignment)?_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"pa-2 pa-sm-4"},[_c('v-row',{staticClass:"mb-4"},[_c('v-col',[(_vm.assetAssignment)?_c('table',{staticClass:"custom-table"},[_c('tbody',[_c('tr',[_c('th',[_vm._v("Número de asignación:")]),_c('td',{domProps:{"textContent":_vm._s(_vm.assetAssignment?.id)}})]),_c('tr',[_c('th',[_vm._v("Responsable:")]),_c('td',{domProps:{"textContent":_vm._s(
												_vm.assetAssignment?.responsible?.fullName
											)}})]),_c('tr',[_c('th',[_vm._v("Usuario asignado:")]),_c('td',{domProps:{"textContent":_vm._s(
												_vm.assetAssignment?.assigned_user
													?.fullName
											)}})]),_c('tr',[_c('th',[_vm._v("Fecha de preasignación:")]),_c('td',{domProps:{"textContent":_vm._s(
												_vm.formatDate(
													_vm.assetAssignment?.preassignment_date
												)
											)}})]),_c('tr',[_c('th',[_vm._v("Servicio:")]),_c('td',{domProps:{"textContent":_vm._s(_vm.assetAssignment?.service?.name)}})]),_c('tr',[_c('th',{staticClass:"primary--text pa-1 pr-4"},[_vm._v(" Estado: ")]),_c('td',{staticClass:"pa-1"},[_c('v-chip',{attrs:{"color":_vm.setColorStatusAssignment(
														_vm.assetAssignment?.status
													)?.background,"text-color":_vm.setColorStatusAssignment(
														_vm.assetAssignment?.status
													)?.color,"small":""}},[_vm._v(" "+_vm._s(_vm.setColorStatusAssignment( _vm.assetAssignment?.status )?.name)+" ")])],1)]),_c('tr',[_c('th',[_vm._v("Comentario:")]),_c('td',{domProps:{"textContent":_vm._s(_vm.assetAssignment?.comment)}})]),_c('tr',[_c('th',[_vm._v("Usuario firmador adicional:")]),_c('td',{domProps:{"textContent":_vm._s(_vm.setLastSigner)}})]),_c('tr',[_c('th',[_vm._v("Evidencias:")]),_c('td',[(
													_vm.assetAssignment?.evidence_files &&
													_vm.assetAssignment?.evidence_files
														.length
												)?_c('div',_vm._l((_vm.assetAssignment?.evidence_files),function(evidence){return _c('v-chip',{key:evidence.id,staticClass:"ma-2",attrs:{"href":evidence.evidence_file,"color":"primary","target":"_blank","outlined":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-link ")]),_vm._v(" Archivo ")],1)}),1):_c('div',[_vm._v("-")])])])])]):_vm._e()])],1)],1),_c('v-tab-item',[(_vm.assetAssignment)?_c('v-card',{staticClass:"pa-4",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-card-title',[_vm._v("Activos asignados")]),_c('div',{staticClass:"ml-auto",staticStyle:{"margin":"10px 0px 10px 0px"}},[_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":!_vm.isPreAssigned,"loading":_vm.loadingDownloadCertificateAssignment,"color":"primary"},on:{"click":_vm.downloadCertificate}},[_vm._v("Certificado de asignación")])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersAssetAssignmentDetail,"items":_vm.assetAssignment?.assetAssignDetail,"items-per-page":10},scopedSlots:_vm._u([{key:`item.assignment_start_date`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.assignment_start_date))+" ")]}},{key:`item.assignment_end_date`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.assignment_end_date))+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('detail-asset-assignment-dialog',{attrs:{"item":item}})]}},{key:`item.status`,fn:function({ item }){return [_c('v-chip',{staticStyle:{"width":"150px","justify-content":"center","align-items":"center"},attrs:{"color":_vm.setColorStatusAssignment(item?.status)
											?.background,"text-color":_vm.setColorStatusAssignment(item?.status)?.color,"small":""}},[_vm._v(" "+_vm._s(_vm.setColorStatusAssignment(item?.status)?.name)+" ")])]}}],null,true)})],1):_vm._e()],1),_c('v-tab-item',[(_vm.assetAssignment)?_c('v-card',{staticClass:"pa-4",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-card-title',[_vm._v("Devoluciones")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headerAssetAssignmentReturn,"items":_vm.assetAssignment?.assetAssignmentReturn,"items-per-page":10},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('div',{staticClass:"d-flex"},[_c('detail-asset-assignment-return-dialog',{attrs:{"item":item,"setLastSignerReturn":_vm.setLastSignerReturn}})],1)]}},{key:`item.status`,fn:function({ item }){return [_c('v-chip',{staticStyle:{"width":"150px","justify-content":"center","align-items":"center"},attrs:{"color":_vm.setColorStatusAssignmentReturn(item?.status)
											?.background,"text-color":_vm.setColorStatusAssignmentReturn(item?.status)
											?.color,"small":""}},[_vm._v(" "+_vm._s(_vm.setColorStatusAssignmentReturn(item?.status) ?.name)+" ")])]}}],null,true)})],1):_vm._e()],1)],1):_vm._e()],1),_c('signer-user-dialog',{attrs:{"item":_vm.assetAssignment},on:{"loadAssignmentById":_vm.getAssetAssignmentById},model:{value:(_vm.userSignerDialog),callback:function ($$v) {_vm.userSignerDialog=$$v},expression:"userSignerDialog"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }