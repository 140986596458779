import { mapState, mapActions } from 'vuex';
import DropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
	name: 'AssignUserDialog',
	props: {
		value: Boolean,
		item: Object,
		//
		returnAsset: Boolean,
	},
	data() {
		return {
			listError: [],
			valid: false,
			loadingSave: false,
			assignUserForm: {
				service: null,
				asset: null,
				assigned_user: null,
				comment: '',
				files: [],
			},
			currentService: null,
			requiredRules: [(v) => !!v || 'El usuario es requerido'],
			responsablesRules: [(v) => !!v || 'El usuario es requerido'],
			commentRules: [
				(v) => !!v || 'El comentario es requerido',
				(v) =>
					v.length <= 400 || 'El comentario debe tener menos de 400 carácteres',
			],
			fileRules: [
				(value) =>
					(value && value.length > 0) || 'Debe subir al menos un archivo.',
			],
			// dropzoneOptions: {
			// 	url: 'https://httpbin.org/post',
			// 	thumbnailWidth: 150,
			// 	maxFilesize: 0.5,
			// 	headers: { 'My-Awesome-Header': 'header value' },
			// 	dictDefaultMessage:
			// 		'Arrastra archivos o haz clic aquí para subir imagenes',
			// 	dictFallbackMessage:
			// 		'Tu navegador no soporta la carga de archivos mediante arrastrar y soltar.',
			// 	dictFileTooBig:
			// 		'El archivo es demasiado grande ({{ fileSize }}MB). Tamaño máximo permitido: {{ maxFilesize }}MB.',
			// 	dictInvalidFileType: 'No puedes subir archivos de este tipo.',
			// },
		};
	},
	computed: {
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		...mapState({
			responsibles: (state) => state.security.usersActive,
		}),
		...mapState('security', ['costCenters', 'managements']),
	},
	created() {
		this.getUsersActive();
	},
	methods: {
		...mapActions('assetAssignment',['createAssetAssignment']),
		...mapActions('security', ['getUsersActive']),

		async save() {
			if (!this.$refs?.form?.validate()) {
				return;
			}

			this.$swal({
				text: '¿Estás seguro de asignar un usuario a este activo?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#0039a6',
				cancelButtonColor: 'grey',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Sí, asignarlo',
			}).then(async (result) => {
				if (result.isConfirmed) {

					this.listError = [];

					const formData = new FormData();

					formData.append(
						'assets_ids',
						JSON.stringify([this.item.id])
					)

					formData.append(
						'assigned_user',
						this.assignUserForm.assigned_user
					)

					formData.append(
						'comment',
						this.assignUserForm.comment
					)

					formData.append(
						'service',
						this.assignUserForm.service
					)

					this.assignUserForm.files.forEach((file) => {
						formData.append(
							'asset_assignment_evidences',
							file
						)
					});

					this.loadingSave = true;

					const { ok, error } = await this.createAssetAssignment({ data: formData });


					if (ok) {
						this.dialog = false;
						await this.$emit('getAssetId');
						this.assignUserForm = {
							service: '',
							asset: '',
							assigned_user: '',
							comment: '',
							files: [],
						};
						this.$nextTick(() => {
							if (this.$refs.form) {
								this.$refs.form.resetValidation();
							}
						});
					} else {
						this.listError = Array.isArray(error?.data?.message)
							? error?.data?.message
							: [error?.data?.message] || [];
					}
					this.loadingSave = false;
				}
			});
		},

		close() {
			this.dialog = false;
			(this.assignUserForm = {
				service: '',
				asset: '',
				assigned_user: '',
				comment: '',
				files: [],
			}),
				(this.listError = []);
			this.$refs?.form?.resetValidation();
		},

		dialogInput(value) {
			this.$nextTick(() => {
				this.$refs.dialogForm.$el.blur();
				this.$refs.form.resetValidation();
				this.dialog = value;
				if (!value) {
					this.assignUserForm = {
						service: '',
						asset: '',
						assigned_user: '',
						comment: '',
						files: [],
					}
					this.listError = [];
				}
			})
		},
	},
	components: {
		DropZone,
	},
};
