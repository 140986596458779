import moment from 'moment';
import { getRating } from '@/helpers/statusIncident.js';
import { getIncidentStatus } from '@/helpers/statusIncident.js';
import ReassignUserDialog from '../../elements/ReassignUserDialog';
// import {  mapActions } from 'vuex';

export default {
	name: 'TicketItem',
	props: {
		value: Boolean,
		tickets: Array,
	},
	
	data() {
		return {
			reassignUserDialog: false,
			source: 'request-list',
			item:null,
			headers: [
				{ text: 'Ticket', sortable: false, value: 'num_ticket' },
				{ text: 'Descripción', sortable: false, value: 'description' },
				{ text: 'Fecha de creación', sortable: false, value: 'created_at' },
				{ text: 'Asignado', sortable: false, value: 'responsible_id.fullName' },
				{ text: 'Estado', sortable: false, value: 'status' },
				{ text: 'Acciones', value: 'actions', sortable: false },
			],
		};
	},
	computed: {
		hasShowDetailPermission() {
			return this.$store.getters['auth/hasActivity'](
				'solicitudes',
				'detalleSolicitud',
				'mostrarSolicitud'
			);
		},
	},

	created() {
		//this.getUsersActive();
	},

	methods: {
		//...mapActions('security', ['getUsersActive']),

		getRating,
		getIncidentStatus,
		changeFilter() { },

		formatDate(dateString) {
			if (!dateString) {
				return '-';
			}
			return moment(dateString).format('DD/MM/YYYY HH:mm');
		},

		reassignUser(item) {
			this.item = Object.assign({}, item);
			this.reassignUserDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},

		getAllTickets() {
			this.$emit('getTickets');
		},

		disableButtonReassignResponsible(item) {
			if (item.status === 'SOLICITADO' ||
				item.status === 'CONSULTA' ||
				item.status === 'RESOLUCION'
			) {
				return false;
			} else {
				return true;
			}
		}
	},

	components: {
		ReassignUserDialog
	},
};
