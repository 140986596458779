import RegisterAssetTransfer from '@/components/inventory/elements/RegisterAssetTransfer/index.vue';
import moment from 'moment';

export default {
	name: 'TransferTable',
	data() {
		return {
			registerTransferFlag: false,
			headers: [
				{
					text: 'Origen',
					align: 'start',
					sortable: false,
					value: 'origin',
				},
				{
					text: 'Destino',
					align: 'start',
					sortable: false,
					value: 'destination',
				},
				{
					text: 'Registrado por',
					sortable: false,
					value: 'responsible.fullName',
				},
				{
					text: 'Fecha de registro',
					align: 'start',
					sortable: false,
					value: 'created_at',
				},
			
				{
					text: 'Acciones',
					sortable: false,
					value: 'actions',
				},
			],
		};
	},

	props: {
		value: Boolean,
		item: Object,
	},

	computed: {},

	created() { 
	},

	methods: {
		getStatusColor(status) {
			switch (status) {
				case 'MOVILIZANDO':
					return 'blue accent-4';
				case 'CANCELADO':
					return 'red';
				case 'RECEPCIONADO':
				case 'RECEPCIONADO (*)':
					return { background: 'green lighten-5', color: 'green' };
				case 'RECHAZADO':
					return 'blue-grey';
				default:
					return ''; // color por defecto o podrías retornar algún color en particular
			}
		},

		formatDate(dateString) {
			if (!dateString) {
				return '-';
			}
			return moment(dateString).format('DD/MM/YYYY HH:mm');
		},

		registerTransfer() {
			this.registerTransferFlag = true;
		},

		transferRegistered(){
			this.$emit('getAssetId');
		}
	},

	components: {
		RegisterAssetTransfer
	},
};
