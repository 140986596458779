var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-card',{staticClass:"pa-4",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-card-title',[_vm._v("Lista de Traspasos")]),_c('div',{staticClass:"ml-auto",staticStyle:{"margin":"10px 0px 10px 0px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.item.status === 'DISPONIBLE' ? false : true,"color":"primary"},on:{"click":function($event){return _vm.registerTransfer()}}},'v-btn',attrs,false),on),[_vm._v(" Traspasar"),_c('v-icon',{staticClass:"ml-2"},[_vm._v(" mdi-truck-delivery-outline ")])],1)]}}])},[_c('span',[_vm._v("Traspasar Activo ")])])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.item.transfers,"items-per-page":10,"dense":""},scopedSlots:_vm._u([{key:`item.origin`,fn:function({ item }){return [(item?.origin_service)?_c('div',[_vm._v(" "+_vm._s(item.origin_service?.costCenter)+" - "+_vm._s(item.origin_service?.name)+" ")]):_vm._e()]}},{key:`item.destination`,fn:function({ item }){return [(item?.destination_service)?_c('div',[_vm._v(" "+_vm._s(item.destination_service?.costCenter)+" - "+_vm._s(item.destination_service?.name)+" ")]):_vm._e()]}},{key:`item.status`,fn:function({ item }){return [_c('v-chip',{attrs:{"small":"","color":_vm.getStatusColor(item.status)?.background,"text-color":_vm.getStatusColor(item.status)?.color}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:`item.created_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":{
								name: 'DetailTransfer',
								params: {
									assetId: _vm.$route.params.assetId,
									transferId: item.id,
								},
							}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalle")])])]}}],null,true)})],1),_c('register-asset-transfer',{attrs:{"item":_vm.item},on:{"transfer-registered":_vm.transferRegistered},model:{value:(_vm.registerTransferFlag),callback:function ($$v) {_vm.registerTransferFlag=$$v},expression:"registerTransferFlag"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }