import { mapState, mapActions } from 'vuex';
import DropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
	name: 'ReturnToWarehouseDialog',
	props: {
		value: Boolean,
		item: Object,
		//
		returnAsset: Boolean,
	},
	data() {
		return {
			listError: [],
			valid: false,
			loadingSave: false,
			returnAssetForm: {
				reason: null,
				service: null,
				comment: null,
				asset_status: null,
				receptor_user: null,
				files: []
			},
			requiredRules: [(v) => !!v || 'El campo es requerido'],
			responsablesRules: [(v) => !!v || 'El usuario es requerido'],
			commentRules: [
				(v) => !!v || 'El comentario es requerido',
				(v) => !v || v.length <= 400 || 'El comentario debe tener menos de 400 caracteres',
			],
			fileRules: [
				(value) =>
					(value && value.length > 0) || 'Debe subir al menos un archivo.',
			],
			motives: [
				{ id: 1, text: 'CESE DE SERVICIO' },
				{ id: 2, text: 'DESPIDO' },
				{ id: 3, text: 'VACACIONES' },
			],
			conditions: [
				{ id: 1, text: 'BUENO' },
				{ id: 2, text: 'MALO' },
				{ id: 3, text: 'DEFICIENTE' },
				{ id: 4, text: 'NO ENTREGO' },
			],
			dropzoneOptions: {
				url: 'https://httpbin.org/post',
				thumbnailWidth: 150,
				maxFilesize: 0.5,
				headers: { 'My-Awesome-Header': 'header value' },
				dictDefaultMessage: 'Arrastra archivos o haz clic aquí para subir evidencias',
				dictFallbackMessage: 'Tu navegador no soporta la carga de archivos mediante arrastrar y soltar.',
				dictFileTooBig: 'El archivo es demasiado grande ({{ fileSize }}MB). Tamaño máximo permitido: {{ maxFilesize }}MB.',
				dictInvalidFileType: 'No puedes subir archivos de este tipo.',
			},
		};
	},
	computed: {
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		...mapState({
			responsibles: (state) => state.security.usersActive,
		}),
		...mapState('security', ['costCenters', 'managements']),
	},
	created() {
	},
	methods: {

		...mapActions('assetAssign', ['assetReturn']),
		...mapActions('assetAssignment', ['assetReturnAssignment']),
		async save() {
			if (!this.$refs?.form?.validate()) {
				return;
			}
			// if (!this.$refs?.form?.validate()) {
			//   return;
			// }
			// this.listError = [];
			// const data = {
			//   ...this.assignUserForm,
			//   company_id: parseInt(localStorage.getItem("company_id")),
			//   asset: this.item.id,
			// };
			// this.loadingSave = true;
			// const { ok, error } = await this.assignUser(data);
			// if (ok) {
			//   this.dialog = false;
			//   //puede que haya un emit
			//   await this.$emit("getAssetId");
			//   this.assignUserForm = {
			//     asset: "",
			//     assigned_user_id: "",
			//     comment: "",
			//   };
			//   this.$nextTick(() => {
			//     if (this.$refs.form) {
			//       this.$refs.form.resetValidation();
			//     }
			//   });
			// } else {
			//   this.listError = Array.isArray(error?.data?.message)
			//     ? error?.data?.message
			//     : [error?.data?.message] || [];
			// }
			// Mostrar SweetAlert para confirmación antes de asignar el usuario.
			this.$swal({
				text: '¿Estás seguro de que deseas realizar esta acción?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#0039a6',
				cancelButtonColor: 'grey',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Sí',
			}).then(async (result) => {
				if (result.isConfirmed) {

					const assetId = this.$route.params.assetId;

					const assignedAssignments = this.item?.assignments
						?.filter(assignment =>
							assignment?.status === "ASIGNADO" &&
							assignment?.asset_id.toString() === assetId.toString()
						)

					this.listError = [];
					const formData = new FormData();

					formData.append(
						'reason',
						this.returnAssetForm.reason
					)

					formData.append(
						'comment',
						this.returnAssetForm.comment
					)

					formData.append(
						'service',
						this.returnAssetForm.service
					)

					formData.append(
						'asset_status',
						this.returnAssetForm.asset_status
					)

					formData.append(
						'receptor_user',
						this.returnAssetForm.receptor_user
					)


					this.returnAssetForm.files.forEach((file) => {
						formData.append(
							'asset_return_evidences',
							file
						)
					});

					formData.append(
						'asset_assignment', assignedAssignments[0]?.asset_assignment_id
					)

					formData.append(
						'asset_assign_ids', JSON.stringify(assignedAssignments.map(assignment => assignment.id))
					)

					this.loadingSave = true;

					const { ok, error } = await this.assetReturnAssignment({ data: formData });

					if (ok) {
						this.dialog = false;
						await this.$emit('getAssetId');
						this.returnAssetForm = {
							reason: '',
							service: null,
							comment: '',
							asset_status: '',
							receptor_user: '',
							files: []
						},
							this.$refs.form.resetValidation();
					} else {
						this.listError = Array.isArray(error?.data?.message)
							? error?.data?.message
							: [error?.data?.message] || [];
					}

					this.loadingSave = false;
				}
			});
		},
		close() {
			this.dialog = false;
			(this.returnAssetForm = {
				reason: '',
				service: null,
				comment: '',
				asset_status: '',
				receptor_user: '',
				files: []
			}),
				(this.listError = []);
			this.$refs?.form?.resetValidation();
		},

		dialogInput(value) {
			this.$nextTick(() => {
				this.$refs.dialogForm.$el.blur();
				this.$refs.form.resetValidation();
				this.dialog = value;
				if (!value) {
					this.returnAssetForm = {
						reason: '',
						service: null,
						comment: '',
						asset_status: '',
						receptor_user: '',
						files: []
					}
					this.listError = [];
				}
			})
		},
	},
	components: {
		DropZone,
	},
};
