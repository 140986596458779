import { mapState, mapActions } from 'vuex';
import CancelActiveDialog from '../../elements/CancelActiveDialog';
import DeleteAssetDialog from '../../elements/DeleteAssetsDialog';
import AssignUserDialog from '../../elements/AssignUserDialog';
import ReestablishAssetDialog from '../../elements/ReestablishAssetDialog';
import AssetUnsubscribeDialog from '../../elements/DetailAssetUnsubscribeDialog';
import AssignSpareDialog from '../../elements/AssignSpareDialog';
import ReturnToWarehouseDialog from '@/components/inventory/elements/ReturnToWarehouseDialog/ReturnToWarehouseDialog.vue';
import ReturnToTiDialog from '@/components/inventory/elements/ReturnToTiDialog/ReturnToTiDialog.vue';
import TransferTable from '@/components/inventory/elements/TransferTable/TransferTable.vue';
import ModificationsTab from '@/components/inventory/elements/ModificationsTab/ModificationsTab.vue';
import MaintenanceTab from '@/components/inventory/elements/MaintenanceTab/MaintenanceTab.vue';
import RegisterAssetMaintenance from '@/components/inventory/elements/RegisterAssetMaintenance/index.vue';
import moment from 'moment';

export default {
	name: 'DetailActive',
	data() {
		return {
			//
			source: 'detail',
			//
			tempItemStatusChange: null,
			isLoading: false,
			listError: [],
			deleteDialog: false,
			cancelActiveDialog: false,
			reestablishAssetDialog: false,
			assignUserDialog: false,
			assetUnsubscribeDetailDialog: false,
			assignSpareDialog: false,
			returnAssetDialog: false,
			registerAssetMaintenanceDialog: false,
			panel1: [0],
			panel2: [0],
			panel3: [0],
			panel4: [0],
			item: {},
			headers: [
				{
					text: 'Serie',
					sortable: false,
					align: 'start',
					value: 'serial_number',
				},
				{
					text: 'Item',
					sortable: false,
					value: 'name',
				},
				{
					text: 'Tipo',
					sortable: false,
					value: 'asset_type.name',
				},
				{
					text: 'Costo',
					sortable: false,
					value: 'cost',
				},
				{
					text: 'Fecha',
					sortable: false,
					value: 'created_at',
				},
				{
					text: 'Responsable',
					sortable: false,
					value: 'responsible_id.fullName',
				},
			],
			headersAssignmentsHistory: [
				{
					text: 'Usuario',
					sortable: false,
					align: 'start',
					value: 'asset_assignment.assigned_user.fullName',
				},
				{
					text: 'Fecha de Asignación',
					sortable: false,
					align: 'start',
					value: 'assignment_start_date',
				},
				{
					text: 'Fecha de Desasignación',
					sortable: false,
					align: 'start',
					value: 'assignment_end_date',
				},
				{
					text: 'Estado',
					sortable: false,
					value: 'status',
				},
				{
					text: 'Acciones',
					sortable: false,
					align: 'start',
					value: 'actions',
				},
			],

			headersAssetUnsubscribe: [
				{
					text: 'Tipo',
					sortable: false,
					align: 'start',
					value: 'type',
				},
				{
					text: 'Fecha de Registro',
					sortable: false,
					align: 'start',
					value: 'created_at',
				},
				{ text: 'Acciones', value: 'actions', sortable: false },
			],
			headerSpare: [
				{
					text: 'Repuesto',
					sortable: false,
					align: 'start',
					value: 'spare.name',
				},
				{
					text: 'Número de serie',
					sortable: false,
					align: 'start',
					value: 'spare.serial_number',
				},
				{
					text: 'Fecha de asignación',
					sortable: false,
					align: 'start',
					value: 'assignment_start_date',
				},
				{
					text: 'Acciones',
					sortable: false,
					align: 'start',
					value: 'actions',
				},
			],
			tab: 0,
			returnTiDialog: false,
			isActiveTi: false,
			releaseAssetFlag: false,
		};
	},
	created() {
		this.getCostCenters();
		this.getAssetId();
	},
	computed: {
		breadcrumbs() {
			return [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Inventario',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'InventoryHome',
					},
				},
				{
					text: 'Activos',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'AssetsPermissions',
					},
				},
				{
					text: 'Detalle de Activo',
					link: true,
					exact: true,
					disabled: false,
					// to: {
					// 	name: 'InventoryHome',
					// },
				},
			];
		},

		remainingUsefulLife() {
			if (this.asset?.purchase_date && this.asset?.useful_life) {
				const purchaseDate = moment(this.asset?.purchase_date);
				const monthsUntilExpiration = this.asset?.useful_life;
				const currentDate = moment();
				const expirationDate = purchaseDate
					.clone()
					.add(monthsUntilExpiration, 'months');

				const monthsRemaining = expirationDate.diff(currentDate, 'months');
				return monthsRemaining > 0 ? monthsRemaining : 0;
			} else {
				return 0;
			}
		},

		monthDepreciation() {
			if (this.asset && this.remainingUsefulLife > 0) {
				return (this.asset?.purchase_value / this.asset?.useful_life).toFixed(1);
			} else {
				return 0;
			}
		},

		residualValue() {
			if (this.asset) {
				return ((this.remainingUsefulLife + 1) * this.monthDepreciation).toFixed(
					1
				);
			} else {
				return 0;
			}
		},

		replacementValue() {
			if (this.asset) {
				return ((this.asset?.purchase_value * 0.25) / 48).toFixed(1);
			}
			return 0;
		},

		monthlyRate() {
			if (this.asset) {
				return (
					parseFloat(this.monthDepreciation) +
					parseFloat(this.replacementValue) +
					(parseFloat(this.asset?.maintenance_cost) || 0)
				);
			}
			return 0;
		},

		totalChargeMonth() {
			if (this.asset) {
				const result =
					parseFloat(this.monthlyRate) + (this.asset?.other_cost || 0);
				return result || 0;
			}
			return 0;
		},

		...mapState('asset', ['asset', 'sparesByAsset']),
	
	},
	methods: {
		...mapActions('asset', ['getAssetById', 'getSparesByAsset', 'cleanAsset']),
	
		...mapActions('spare', ['listAllSpares']),

		...mapActions('security', ['getCostCenters']),

		async getAssetId() {
			this.isLoading = true;
			const id = this.$route.params.assetId;
			try {
			
				let response;

				response = await this.getAssetById(id);
				if (response.error) {
					this.listError.push(
						response.error?.data?.message || 'Error en getAssetById'
					);
				}

				response = await this.listAllSpares({ status: 'DISPONIBLE' });
				if (response.error) {
					this.listError.push(
						response.error?.data?.message || 'Error en listAllSpares'
					);
				}
			} catch (error) {
				console.error(error);
				this.listError = Array.isArray(error?.data?.message)
					? error?.data?.message
					: [error?.data?.message] || [];
			} finally {
				this.isLoading = false;
			}
		},
		deleteItem(asset) {
			this.item = Object.assign({}, asset);
			this.deleteDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
		detailAssetUnsubscribe(item) {
			this.item = Object.assign({}, item);
			this.assetUnsubscribeDetailDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
		assignSpareItem(item) {
			this.item = Object.assign({}, item);
			this.assignSpareDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
		reestablishAsset(item) {
			this.item = Object.assign({}, item);
			this.reestablishAssetDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
		cancelActiveItem(asset) {
			this.item = Object.assign({}, asset);
			this.cancelActiveDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
		toggleActiveStatus(item) {
			this.tempItemStatusChange = item;
			if (item.status === 'INACTIVO') {
				this.cancelActiveItem(item);
			} else {
				this.reestablishAsset(item);
			}
		},
		handleDialogCancel(payload) {
			if (!payload.saved) {
				this.tempItemStatusChange.status =
					this.tempItemStatusChange.status === 'ACTIVO' ? 'INACTIVO' : 'ACTIVO';
			}
			this.tempItemStatusChange = null;
		},
		assignUserItem(asset) {
			this.item = Object.assign({}, asset);
			this.assignUserDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
		returnAsset() {
			// this.item = Object.assign({}, asset);
			this.returnAssetDialog = true;
			// this.$nextTick(() => {
			// 	document.activeElement.blur();
			// });
		},
		returnAssetTi(asset) {
			this.item = Object.assign({}, asset);
			this.returnTiDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
		getAssetStatus(item) {
			return item.assignment_end_date
				? { status: 'off', color: 'red' }
				: { status: 'on', color: 'green' };
		},
		formatDate(dateString, hour = true) {
			if (!dateString) {
				return '-';
			}
			return hour ? moment(dateString).format('DD/MM/YYYY HH:mm') : moment(dateString).format('DD/MM/YYYY')
		},
		formatDateTest(dateString) {
			if (!dateString) {
				return '-';
			}
			return moment(dateString).utc().format('DD/MM/YYYY');
		},
		redirectToDetail(item) {
			this.$router.push({
				name: 'DetailSpareInventory',
				params: { spareId: item.spare.id },
			});
		},
		activeTi() {
			//this.isActiveTi = true;
			this.asset.status = 'REVISION';
		},

		setColorStatus(status) {
			switch (status) {
				case 'DISPONIBLE':
					return {
						background: 'green lighten-5',
						color: 'green',
						name: status,
					};
				case 'PREASIGNADO':
					return {
						background: 'light-blue lighten-5',
						color: 'light-blue',
						name: status,
					};
				case 'ASIGNADO':
					return {
						background: 'blue lighten-5',
						color: 'blue',
						name: status,
					};
				case 'REVISION':
					return {
						background: '#FFF3E0',
						color: 'orange',
						name: status,
					};
				case 'MANTENIMIENTO':
					return {
						background: 'purple lighten-5',
						color: 'purple',
						name: status,
					};
				case 'BAJA':
					return {
						background: 'grey lighten-3',
						color: 'grey',
						name: status,
					};
				case 'OBSERVADO':
					return {
						background: 'grey lighten-2',
						color: 'grey',
						name: status,
					};
			}
		},

		setColorStatusAssignment(status) {
			switch (status) {
				case 'PREASIGNADO':
					return {
						background: 'light-blue lighten-5',
						color: 'light-blue',
						name: status,
					};
				case 'ANULADO':
					return {
						background: 'red lighten-5',
						color: 'red',
						name: status,
					};
				case 'DECLINADO':
					return {
						background: '#FFF3E0',
						color: 'orange',
						name: status,
					};
				case 'ASIGNADO':
					return {
						background: 'green lighten-5',
						color: 'green',
						name: status,
					};
				case 'DESASIGNADO':
					return {
						background: 'grey lighten-3',
						color: 'grey',
						name: status,
					};
				case 'DESASIGNADO_BAJA':
					return {
						background: 'grey lighten-4',
						color: 'grey',
						name: 'DESASIGNADO-BAJA',
					};

			}
		},

		assignAsset() {
			// asset.status = 'ASIGNADO';
			this.assignUserDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
		toMaintenance() {
			// asset.status = 'MANTENIMIENTO';
			this.releaseAssetFlag = false;
			this.registerAssetMaintenanceDialog = true;
		},
		releaseAsset() {
			this.releaseAssetFlag = true;
			this.registerAssetMaintenanceDialog = true;
		},
		printEndUsefulLife({ purchase_date, useful_life }) {
			try {
				if (purchase_date && useful_life) {
					const initialDate = moment(purchase_date);
					const finalDate = initialDate.add(useful_life, 'months');
					return finalDate.format('DD/MM/YYYY');
				}
			} catch (error) {
				return '-';
			}
		},
	},

	beforeDestroy() {
		//this.cleanAssets();
		this.cleanAsset();
	},

	components: {
		CancelActiveDialog,
		DeleteAssetDialog,
		AssignUserDialog,
		ReestablishAssetDialog,
		AssetUnsubscribeDialog,
		AssignSpareDialog,
		ReturnToWarehouseDialog,
		ReturnToTiDialog,
		TransferTable,
		ModificationsTab,
		MaintenanceTab,
		RegisterAssetMaintenance,
	},
};
