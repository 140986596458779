import { mapActions, mapState } from "vuex";
import { showError } from "@/helpers/globalHelpers";
import { showSnackBar } from "@/helpers/globalHelpers";
export default {
  name: 'ReturnMultipleAssignmentsView',

  data() {
    return {
      loading: false,
      isValidForm: null,
      isLoadingUsers: false,
      isLoadingManagements: false,
      loadingSend: false,
      selectedAssets: [],
      requiredRule: [(v) => !!v || "Campo requerido"],
      formRA: {
        asset_assign_ids: [],
        asset_assignment: this.$route.params.assetAssignmentId,
        reason: null,
        service: null,
        receptor_user: null,
        comment: null,
        asset_status: null,
        asset_return_evidences: []
      },
      motives: [
        { id: 1, text: 'CESE DE SERVICIO' },
        { id: 2, text: 'DESPIDO' },
        { id: 3, text: 'VACACIONES' },
      ],
      conditions: [
        { id: 1, text: 'BUENO' },
        { id: 2, text: 'MALO' },
        { id: 3, text: 'DEFICIENTE' },
        { id: 4, text: 'NO ENTREGO' },
      ],
      assetsHeader: [
        { text: "Nombre", sortable: false, value: "asset.name" },
        // { text: "Subfactores", sortable: false, value: "sub_factor.name" },
        // { text: "Preguntas", sortable: false, value: "description" },
        // { text: "Acciones", sortable: false, value: "actions", align: "right" },
      ],
    }
  },

  watch: {
    selectedAssets(newVal) {
      this.formRA.asset_assign_ids = newVal.map(item => item.id);
    }
  },

  created() {
    this.getAssetAssignmentById();
    this.loadUsers();
    this.loadManagements();
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: 'Inicio',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'Dashboard',
          },
        },
        {
          text: 'Inventario',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'InventoryHome',
          },
        },
        {
          text: 'Activos',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "AssetsPermissions",
          },
        },
        {
          text: 'Lista de asignaciones',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "MultipleAssignmentsList",
          },
        },
        {
          text: 'Detalle de asignación',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'DetailMultipleAssignments',
            params: { assetAssignmentId: this.$route.params.assetAssignmentId }
          },
        },
        {
          text: 'Devolución de activos',
          link: true,
          exact: true,
          disabled: false,
          // to: {
          //   name: 'DetailMultipleAssignments',
          //   params: { assetAssignmentId: this.$route.params.assetAssignmentId }
          // },
        },
      ];
    },
    ...mapState("security", [
      "usersActive",
      "costCenters",
      "managements"
      //  "costCenters"
    ]),
    ...mapState('assetAssignment', ['assetAssignment']),
    assetAvailables() {
      return this.assetAssignment?.assetAssignDetail.filter(res=> res.status ==='ASIGNADO')
    },
  },

  methods: {
    ...mapActions("security", [
      "getUsersActive",
      "getCostCenters",
      //  "getCostCenter"
    ]),

    ...mapActions('assetAssignment', ['getAssignmentById', 'assetReturnAssignment']),

    loadUsers: async function () {
      this.isLoadingUsers = true;
      await this.getUsersActive();
      this.isLoadingUsers = false;
    },

    loadManagements: async function () {
      this.isLoadingManagements = true;
      await this.getCostCenters();
      this.isLoadingManagements = false;
    },

    async getAssetAssignmentById() {
      const { assetAssignmentId } = this.$route.params
      this.loading = true;
      const { error } = await this.getAssignmentById(assetAssignmentId);
      if (error) showError(error);
      this.loading = false;

    },

    validationForm: function () {
      const formCompleted = this.$refs.form.validate();
      // const periodCompleted = this.selectedDates.length > 0;
      const assetCompleted = this.selectedAssets.length > 0;

      if (!formCompleted) {
        showSnackBar("Completar el formulario", "warning");
        return false;
      }

      // if (!periodCompleted) {
      //   showSnackBar("Completar el período", "warning");
      //   return false;
      // }

      if (!assetCompleted) {
        showSnackBar("Seleccionar los activos", "warning");
        return false;
      }

      if (formCompleted && assetCompleted) {
        this.saveAssetAssignmentReturn();
      }
    },

    saveAssetAssignmentReturn: async function () {
      this.loadingSend = true;

      const formData = new FormData();

      formData.append(
        'asset_assign_ids',
        JSON.stringify(this.formRA.asset_assign_ids)
      )

      formData.append(
        'asset_assignment',
        this.formRA.asset_assignment
      )

      formData.append(
        'reason',
        this.formRA.reason
      )

      formData.append(
        'service',
        this.formRA.service
      )

      formData.append(
        'receptor_user',
        this.formRA.receptor_user
      )

      formData.append(
        'comment',
        this.formRA.comment
      )

      formData.append(
        'asset_status',
        this.formRA.asset_status
      )

      if (this.formRA.asset_return_evidences && this.formRA.asset_return_evidences.length) {
        this.formRA.asset_return_evidences.forEach(file => {
          formData.append(
            'asset_return_evidences',
            file
          )
        })
      }

      const { ok, error } = await this.assetReturnAssignment({ data: formData });
      if (ok) {
        this.$swal.fire({
          title: "¡La devolución se completó con éxito!",
          // text: "En un momento te enviaremos la aprobación por correo para que puedas subir tu evidencia, gracias",
          icon: "success",
          confirmButtonColor: "#0039a6",
          confirmButtonText: "Ok",
        });
        this.$router.push({ name: "DetailMultipleAssignments", params: { assetAssignmentId: this.$route.params.assetAssignmentId } });
      } else {
        let errorMsm = error?.data?.message;
        errorMsm = Array.isArray(errorMsm) ? errorMsm.join(" <br> ") : errorMsm;
        showSnackBar(errorMsm, "error");
      }
      this.loadingSend = false;
    }
  },

  beforeDestroy() {

  },

  components: {

  }
}